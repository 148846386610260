import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import ProgressBar from "./ProgressBar";
import IframeResizer from "iframe-resizer-react";

import "../styles/main.css";
import "../styles/overrideMaterialUI.css";

let iOSversion;
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
  var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  iOSversion = [
    parseInt(v[1], 10),
    parseInt(v[2], 10),
    parseInt(v[3] || 0, 10),
  ];
} else {
  iOSversion = [];
}
let iOS12orLess = false;
if (iOSversion[0] <= 12) {
  iOS12orLess = true;
}

export class ThirdPage extends Component {
  componentDidMount() {
    this.saveToLocalStorage();
    window.scrollTo(0, 0);
    this.props.getPaymentIframeSrc();
  }

  saveToLocalStorage = () => {
    this.props.saveToLocalStorage();
  };

  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };


  render() {
    const { cancel } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <div className="white-space-side">
              <img
                className="pageImg"
                src={require("../assets/UniMastery120x120.png")}
                title="first page"
                alt="pic"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div style={{ direction: "ltr", marginleft: "125px" }}>
              <ProgressBar currStep={this.props.currStep} />
            </div>

            <div className="main-content">
              <div className="wrapper" style={{ padding: "0" }}>
                <div className="iframe-holder">
                  <div className="iframe-wrapper">
                    {this.props.showLoader ? (
                      <div className="loader-iframe">
                        <CircularProgress
                          style={{
                            color: "#32C6A9",
                            width: "100px",
                            height: "100px",
                          }}
                        />
                      </div>
                    ) : null}

                    {/* CHECK IF IOS IS LESS THAN 13 */}
                    {iOS12orLess ? (
                      <IframeResizer
                        src={this.props.iframeSrc}
                        style={{
                          width: "1px",
                          minWidth: "100%",

                          overflowY: "scroll",
                        }}
                        scrolling="no"
                      />
                    ) : (
                      <IframeResizer
                        src={this.props.iframeSrc}
                        style={{
                          width: "1px",
                          minWidth: "100%",
                          height: "1px",
                          minHeight: "100%",
                          overflowY: "scroll",
                        }}
                        scrolling="yes"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* {this.props.openMessageModal ? (
                <div id="myModal" class="modal">
                  <div class="modal-content">
                    <p>הקוד שהכנסת שגוי</p>
                    <button
                      onClick={this.props.handleModalClose}
                      className="modal-close"
                      style={{ background: "#fff" }}
                    >
                      ok
                    </button>
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="button-container" dir="ltr">
              <div
                className="button-back"
                variant="contained"
                onClick={this.back}
              >
                <i
                  className="material-icons"
                  style={{
                    fontSize: "32px",
                    marginRight: "5px",
                    marginLeft: "-12px",
                  }}
                >
                  keyboard_arrow_left
                </i>
                Back
              </div>

              <div
                className="button-cancel"
                variant="contained"
                onClick={cancel}
              >
                Cancel
              </div>
            </div>
            <div className="contact-info-div">
              <p
                className="contact-text-bottom"
                variant="caption"
                display="block"
              >
                for any questions please contact us at
                <br />
                טלפון: <a href={"tel:052-8808558"}>052-8808558</a> מייל:{" "}
                <a href={"mailto:contact@unimastery.com"}>contact@unimastery.com</a>
              </p>
              <p className="terms">
                <br />
                <a href={"https://class.me/termsHE.html"}>
                  תנאי שימוש ומדיניות פרטיות
                </a>
              </p>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="white-space-side"></div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ThirdPage;
