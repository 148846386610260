import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Cities from "../data/cities-keys";
import { Autocomplete } from "@material-ui/lab";

const vw = Math.max(
  document.documentElement.clientWidth,
  window.innerWidth || 0
);
const mobileViewport = vw < 768 ? true : false;

let citiesKeys = JSON.parse(JSON.stringify(Cities));
const suggestions = citiesKeys.Cities.map((value) => value);

const useStyles = makeStyles({
  root: {
    display: "flex",
    marginBottom: mobileViewport ? "20px" : "32px",

    "& label": {
      color: "#4A4547",
      fontSize: mobileViewport ? "14px" : "16px",
    },

    "& label.Mui-focused": {
      color: "#32C6A9",
      fontSize: mobileViewport ? "14px" : "16px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #707070",
        padding: "0px",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #32C6A9",
        paddingTop: "20px",
        color: "black",
      },
      padding: "0px",
      height: "48px",
    },
  },
  option: {
    fontSize: mobileViewport ? "14px" : "16px",
    // "& > span": {
    //   marginRight: 10,
    //   fontSize: 18,
    // },
  },
});

function AutocompleteSearch(props) {
  const setValue = React.useState(null)[1];
  const classes = useStyles();

  const handleCityValues = (event, newValue) => {
    setValue(newValue);
    props.handleCityValues(newValue);
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      classes={{
        option: classes.option,
        root: classes.root,
      }}
      options={suggestions}
      getOptionLabel={(option) => option.city_name || option}
      style={{ width: "100%" }}
      value={props.citySelected}
      onChange={(event, newValue) => handleCityValues(event, newValue)}
      renderInput={(params) => (
        <TextField {...params} label="*עיר" variant="outlined" required />
      )}
    />
  );
}
export default AutocompleteSearch;
