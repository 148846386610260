import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Typography, TextField } from '@material-ui/core/';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ProgressBar from './ProgressBar';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutocompleteSearch from './SelectAutocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import UniMasteryMobileLogo from '../assets/UniMasterymobilelogo.png';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



//import "../styles/main.css";
import '../styles/overrideMaterialUI.css';

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const mobileViewport = vw < 768 ? true : false;

const themeEn = createTheme({
	direction: 'ltr'
});
const themeHeb = createTheme({
	direction: 'rtl'
});

const StyledTextFieldEn = withStyles({
	root: {
		width: '100%',
		height: '48px',
		padding: '0px',

		dir: 'ltr',
		opacity: 1,
		color: '#4A4547',
		borderRadius: '4px',
		marginBottom: mobileViewport ? '20px' : '32px',
		'& label': {
			color: '#4A4547',
			fontSize: mobileViewport ? '14px' : '16px'
		},

		'& label.Mui-focused': {
			color: '#32C6A9',
			fontSize: mobileViewport ? '14px' : '16px'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #707070',
				opacity: 1,
				padding: '0px'
			},
			'&.Mui-focused fieldset': {
				border: '2px solid #32C6A9'
			},
			padding: '0px',
			height: '48px'
		}
	}
})(TextField);

const StyledTextFieldHeb = withStyles({
	root: {
		width: '100%',
		height: '48px',
		padding: '0px',
		dir: 'rtl',
		opacity: 1,
		color: '#4A4000',
		borderRadius: '4px',
		marginBottom: mobileViewport ? '20px' : '32px',
		'& label': {
			color: '#4A4547',
			fontSize: mobileViewport ? '14px' : '16px'
		},

		'& label.Mui-focused': {
			color: '#32C6A9',
			fontSize: mobileViewport ? '14px' : '16px'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #707070',
				opacity: 1,
				padding: '0px'
			},
			'&.Mui-focused fieldset': {
				border: '2px solid #32C6A9'
			},
			padding: '0px',
			height: '48px'
		}
	}
})(TextField);

const StyledButtonNextEn = withStyles({
	root: {
		display: 'flex',
		height: '48px',
		background: '#32C6A9 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		opacity: 1,
		fontSize: mobileViewport ? '14px' : '18px',
		fontWeight: 'bold',
		boxShadow: 'none',

		'&:hover': {
			backgroundColor: '#32C6A9',
			boxShadow: '0px 3px 6px #32C6A9B3'
		},
		'&:active': {
			backgroundColor: '#4A4547',
			boxShadow: 'none'
		}
	}
})(Button);

const StyledButtonNextHeb = withStyles({
	root: {
		display: 'flex',
		height: '48px',
		background: '#32C6A9 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		opacity: 1,
		shadow: 'none',
		fontSize: mobileViewport ? '14px' : '18px',
		fontWeight: 'bold',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#32C6A9',
			boxShadow: '0px 3px 6px #32C6A9B3'
		},
		'&:active': {
			backgroundColor: '#4A4547'
		}
	}
})(Button);

const StyledTextEn = withStyles({
	root: {
		width: '83%',
		textAlign: 'right',
		margin: mobileViewport ? '17px 0 10px 0' : '24px 0 10px 0',
		fontSize: mobileViewport ? '14px' : '16px',
		letterSpacing: 0,
		color: '#4A4547',
		opacity: 1,
		lineHeight: '1.5rem'
	}
})(Typography);

const StyledTextHeb = withStyles({
	root: {
		width: '83%',
		textAlign: 'left',
		margin: mobileViewport ? '17px 0 10px 0' : '24px 0 10px 0',
		fontSize: mobileViewport ? '14px' : '16px',
		letterSpacing: 0,
		color: '#4A4547',
		opacity: 1,
		lineHeight: '1.5rem'
	}
})(Typography);

const StyledFormControlLabel = withStyles({
	root: {
		color: '#4A4547'
	}
})(FormControlLabel);

const StyledCheckboxFormControlLabelEn = withStyles({
	root: {
		color: '#4A4547',
		marginRight: '-9px'
	}
})(FormControlLabel);

const StyledCheckboxFormControlLabelHeb = withStyles({
	root: {
		color: '#4A4547',
		marginLeft: '-9px'
	}
})(FormControlLabel);

const StyledFormControl = withStyles({
	root: {
		// marginTop: "24px",
		display: 'block',
		color: '#4A4547'
	}
})(FormControl);

const StyledRadio = withStyles({
	root: {
		'& svg:first-child': {
			//transform: "scale(1)",
			transform: 'translateZ(0)'
		}
	}
})(Radio);

// labels
const fullNameLabel = '*Full Name';
const mobileLabelEn = '*Your Mobile Number';
const emailLabel = '*Email';
const master_slogan = '';
const fullNameLabelHeb = '*שם מלא';
const mobileLabelHeb = '*מספר הנייד שלך';
const emailLabelHeb = '*כתובת מייל';

export class FirstPage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	saveToLocalStorage = () => {
		this.props.saveToLocalStorage();
	};

	continue = async (e) => {
		e.preventDefault();
		this.saveToLocalStorage();
		this.props.nextStep();
	};

	render() {
		const {
			values,
			handleChangeName,
			handleChangePhoneNumber,
			handleChangeEmailAddress,
			handleChangeLanguage,
			handleChangeClassRadio,
			language,
			cancel
		} = this.props;

		console.log('render--> langague is:', language);
		// labels by language
		const fullName = language === 'HEB' ? fullNameLabelHeb : fullNameLabel;
		const mobileLabel = language === 'HEB' ? mobileLabelHeb : mobileLabelEn;
		const emailLabelL = language === 'HEB' ? emailLabelHeb : emailLabel;
		const theme = language === 'HEB' ? themeHeb : themeEn;
		// ui style by language
		const direction = language === 'HEB' ? 'rtl' : 'ltr';
		const StyledText = language === 'HEB' ? StyledTextHeb : StyledTextEn;
		const StyledTextField = language === 'HEB' ? StyledTextFieldHeb : StyledTextFieldEn;
		const StyledCheckboxFormControlLabel =
			language === 'HEB' ? StyledCheckboxFormControlLabelHeb : StyledCheckboxFormControlLabelEn;
		const StyledButtonNext = language === 'HEB' ? StyledButtonNextHeb : StyledButtonNextEn;
		const contactInfoDivClasas = language === 'HEB' ? 'contact-info-div-heb' : 'contact-info-div';

		return (
			<React.Fragment>
				<Grid container spacing={0}>
					<Grid item xs={2}>
						<div className="white-space-side">
							<img
								className="pageImg"
								src={require('../assets/UniMastery120x120.png')}
								title="first page"
								alt="pic"
							/>
						</div>
					</Grid>
					<Grid item xs={8}>
						<div dir={direction}>
							<ProgressBar
								currStep={this.props.currStep}
								totalSteps={this.props.totalSteps}
								language={this.props.language}
							/>
						</div>
						<form onSubmit={this.continue} className="page-form">
							<div className="main-content">
								<div className="wrapper" dir={direction}>
									<ThemeProvider theme={theme}>
										<StyledTextField 
											autoFocus
											required={true}
											label={fullName}
											variant="outlined"
											value={values.fullName}
											onChange={(e) => handleChangeName(e)}
										/>
									</ThemeProvider>

									<PhoneInput
										style={{direction: "ltr"}}
										inputExtraProps={{
											required: true,
											autoFocus: true,
										}}
										
										country={'il'}
										value={values.countryCode}
										onChange={(e) => handleChangeLanguage(e)}
										/>
										<br/>
										<p className="error-text">{this.props.invalidPhone ? 'invalid number' : ' '}</p>


									<ThemeProvider theme={theme}>
										<StyledTextField
											type="email"
											pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
											error={this.props.invalidEmail ? true : false}
											required={true}
											label={emailLabelL}
											variant="outlined"
											value={values.email}
											onChange={(e) => handleChangeEmailAddress(e)}
										/>

										<p className="error-text">{this.props.invalidEmail ? 'invalid email' : ' '}</p>
									</ThemeProvider>

									{this.props.customerFieldValue ? (
										<ThemeProvider theme={theme}>
											<StyledTextField
												required={true}
												label={this.props.customerFieldLabel}
												variant="outlined"
												value={this.props.customerFieldValue}
											/>
										</ThemeProvider>
									) : (
										<ThemeProvider theme={theme}>
											<AutocompleteSearch
												handleCityValues={this.props.handleCityValues}
												citySelected={values.citySelection}
											/>
										</ThemeProvider>
									)}

									{this.props.isUserPaying && this.props.trialData.days > 0 ? (
										<ThemeProvider>
											<StyledFormControl>
												<StyledCheckboxFormControlLabel
													control={
														<Checkbox
															checked={values.showTrialPeriod}
															onChange={this.props.handleCheckboxTrial}
															value="trial"
															style={{
																fontSize: mobileViewport ? '14px' : '16px',
																color: '#4A4547',
																marginLeft: '0'
															}}
														/>
													}
													label={this.props.trialData.text}
												/>
												{values.showTrialPeriod ? (
													<div
														className={
															language === 'HEB' ? 'checkbox-text-heb' : 'checkbox-text'
														}
													>
														ניתן לבטל את התשלום עד {this.props.trialData.days} ימים מיום
														ההרשמה
														<i
															className="material-icons info-icon"
															style={{
																fontSize: '22px',
																marginRight: '5px',
																marginLeft: '-12px',
																color: '#1565C0'
															}}
														>
															info
														</i>
													</div>
												) : null}
											</StyledFormControl>
										</ThemeProvider>
									) : (
										''
									)}
									{/* <ThemeProvider>
                      <StyledFormControl>
                        <StyledCheckboxFormControlLabel
                          control={
                              <Checkbox
                              value="tnc"
                              style={{
                                fontSize: mobileViewport ? "14px" : "16px",
                                color: "#4A4547",
                                marginLeft: "0",
                              }}
                              required={true}
                            />
                          }
                          label="אני מסכים ל"
                        />
                        <StyledText><a href='https://class.me/termsHE.html' target='_blank'>תנאי השימוש</a></StyledText>
                      </StyledFormControl>
                    </ThemeProvider> */}
									{this.props.canChooseGender ? (
										<StyledText
											variant="overline"
											display="block"
											className={language === 'HEB' ? 'text-heb' : 'text'}
											gutterBottom
											dir={direction}
										>
											במידה ויתאפשר האם תרצה להיות בכיתה:
										</StyledText>
									) : (
										''
									)}
									{this.props.canChooseGender ? (
										<ThemeProvider>
											<FormControl>
												<RadioGroup
													value={values.classRadio}
													aria-label="classRadio"
													name="customized-radios"
													onChange={(e) => handleChangeClassRadio(e)}
												>
													<StyledFormControlLabel
														value="מעורבת (בנים ובנות)"
														control={<StyledRadio color="default" />}
														label="מעורבת (בנים ובנות)"
														labelPlacement="end"
													/>
													<StyledFormControlLabel
														value="בנים לחוד"
														control={<StyledRadio color="default" />}
														label="בנים לחוד"
														labelPlacement="end"
													/>
													<StyledFormControlLabel
														value="בנות לחוד"
														control={<StyledRadio color="default" />}
														label="בנות לחוד"
														labelPlacement="end"
													/>
												</RadioGroup>
											</FormControl>
										</ThemeProvider>
									) : (
										''
									)}
									{this.props.canChooseLanguage ? (
										<StyledText
											variant="overline"
											display="block"
											className={language === 'HEB' ? 'text-heb' : 'text'}
											gutterBottom
											dir={direction}
											style={{
												margin: mobileViewport ? '12px 0 10px 0' : '20px 0 10px 0'
											}}
										>
											במידת האפשר האם תרצה לבחור בין שיעורים בעברית או ערבית
										</StyledText>
									) : (
										''
									)}
									{this.props.canChooseLanguage ? (
										<ThemeProvider>
											<FormControl>
												<RadioGroup
													value={values.language}
													aria-label="language"
													name="customized-radios"
													onChange={(e) => handleChangeLanguage(e)}
												>
													<StyledFormControlLabel
														value="עברית"
														control={<StyledRadio color="default" />}
														label="עברית"
														labelPlacement="end"
													/>
													<StyledFormControlLabel
														value="ערבית"
														control={<StyledRadio color="default" />}
														label="ערבית"
														labelPlacement="end"
													/>
												</RadioGroup>
											</FormControl>
										</ThemeProvider>
									) : (
										''
									)}

									{this.props.customerFieldValue === "אליאב אללוף" ? (
									<div className="text_mentor" >
										<div className="first" dir="ltr">zone מחפשים שינוי? רוצים להיכנס ל</div> &nbsp;
										<div className="second" dir="ltr">?עם אליאב אללוף </div> &nbsp;
										<div className="third" dir="ltr">!הגעתם למקום הנכון</div>  
										{/* !אליאב אללוף? הגעתם למקום הנכון zone מחפשים שינוי? רוצים להיכנס ל */}
										</div>
									) : ('')}
								</div>
								{/* white space div */}
								<div className="white-space-wrapper-bottom" />
							</div>

							<div
								className="button-container"
								dir={direction}
								style={{
									justifyContent: direction === 'ltf' ? 'flex-start' : 'flex-end',
									width: 'calc(100%-174px)'
								}}
							>
								<div
									className="button-cancel"
									variant="contained"
									onClick={cancel}
									style={{ marginRight: '13%' }}
								>
									{/* no needed */}
									{/* {direction === "ltf" ? "Cancel" : "לבטל"} */}
								</div>

								<StyledButtonNext
									color="secondary"
									variant="contained"
									type="submit"
									disabled={this.props.invalidPhone || this.props.invalidSchoolname ? true : false}
								>
									{direction === 'ltr' ? 'Continue' : 'המשך'}
									<i
										className="material-icons"
										style={{
											fontSize: '32px',
											marginRight: direction === 'ltf' ? '5px' : '-12x',
											marginLeft: direction === 'ltf' ? '-12px' : '5x'
										}}
									>
										{direction === 'ltr' ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
									</i>
								</StyledButtonNext>
							</div>
							{/* unimastery logo (powered by) */}
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '16px'
								}}
							>
								<span class="Powered-By">Powered By</span>
								<img src={UniMasteryMobileLogo} class="Unimastery-mobile-logo" />
							</div>
							{/* <div className={contactInfoDivClasas}>
                <p
                  className="contact-text-bottom"
                  variant="caption"
                  display="block"
                >
                  for any questions please contact us at
                  <br />
                  phone: <a href={"tel:052-8808558"}>052-8808558</a> email:{" "}
                  <a href={"mailto:unimastery.com"}>contact@unimastery.com</a>
                </p>
                <p className="terms">
                  <br />
                  <a href={"https://class.me/termsHE.html"}>
                    Terms and Privacy Policy
                  </a>
                </p>
              </div> */}

							{direction === 'ltr' ? (
								<div className="contact-info-div">
									<p className="contact-text-bottom" variant="caption" display="block">
										for any questions please contact us at
										<br />
										טלפון: <a href={'tel:052-8808558'}>052-8808558</a> Email:{' '}
										<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
									</p>
									<p className="terms">
										<br />
										<a href={'https://class.me/termsHE.html'}>Terms of use and privacy policy</a>
									</p>
								</div>
							) : (
								// or (if not english)

								// if hebrew
								<div className="contact-info-div">
									<p className="contact-text-bottom" variant="caption" display="block">
										לכל שאלה ובקשה ניתן לשלוח דוא"ל לכתובת
										<br />
										טלפון: <a href={'tel:052-8808558'}>052-8808558</a> מייל:{' '}
										<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
									</p>
									<p className="terms">
										<br />
										<a href={'https://class.me/termsHE.html'}>תנאי שימוש ומדיניות פרטיות</a>
									</p>
								</div>
							)}
						</form>
					</Grid>

					<Grid item xs={2}>
						<div className="white-space-side" />
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default FirstPage;
