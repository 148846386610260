import * as Constants from "../constants";

const oneTimeScriptUrl = "https://www.paypal.com/sdk/js?client-id=" + Constants.PAYPAL_CLIENT_ID;
const subscriptionScriptUrl = "https://www.paypal.com/sdk/js?client-id=" + Constants.PAYPAL_CLIENT_ID + "&vault=true&intent=subscription";

/**
 * 
 * @param {Int} currentPaypalPaymentMethod  the currently selected payment method. 
 * if currentPaymentMethod is different then the currently loaded script, load the corrosponding script
 * otherwise, return and do nothing
 * @param {*} callback NA
 */
const reloadPayPalScript = (currentPaypalPaymentMethod, callback) => {

  let currentLoadedScriptUrl = currentPaypalPaymentMethod === Constants.PaypalOneTimePayment ? oneTimeScriptUrl : subscriptionScriptUrl;
  let scriptUrlToLoad = currentPaypalPaymentMethod === Constants.PaypalOneTimePayment ? subscriptionScriptUrl : oneTimeScriptUrl;

  const existingScript = document.querySelector('script[src="' + currentLoadedScriptUrl + '"]');

  if (Boolean(existingScript)) {
    // remove current script
    document.head.removeChild(existingScript);
  }
  // if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptUrlToLoad;
    script.id = 'paypal';
    document.head.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  // }
  
};
export default reloadPayPalScript;
