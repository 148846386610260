import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import '../styles/main.css';
import '../styles/overrideMaterialUI.css';

const StyledTitle = withStyles({
	root: {
		fontSize: '20px',
		letterSpacing: 0,
		color: '#4A4547',
		opacity: 1,
		lineHeight: '1.7rem',
		align: 'left',
		marginBottom: '16px'
	}
})(Typography);

const breakLine = (text) => {
	var br = React.createElement('br');
	var regex = /(<br \/>)/g;
	return text.split(regex).map(function(line, index) {
		return line.match(regex) ? <br key={'key_' + index} /> : line;
	});
};

export class Success extends Component {
	componentDidMount() {
		this.sendRequestToAPI();

		window.scrollTo(0, 0);
	}

	getSuccessData = () => {
		let allValues;
		try {
			const serializedState = localStorage.getItem('state');
			if (serializedState === null) {
				return undefined;
			}
			allValues = JSON.parse(serializedState);
		} catch (err) {
			allValues = undefined;
		}
		console.log('succes all values:', allValues);
		const { successData } = allValues;
		console.log('getSuccessData-> successData', successData);

		return successData;
	};

	sendRequestToAPI() {
		this.props.finish();
	}

	render() {
    const {
			language,
		} = this.props;

		let textData = this.getSuccessData();
    const direction = language === 'HEB' ? 'rtl' : 'ltr';
		console.log('*****texgdata is:', textData);

		return (
			<React.Fragment>
				<Grid container spacing={0}>
					<Grid item xs={2}>
						<div className="white-space-side">
							<img
								className="pageImg"
								src={require('../assets/UniMastery120x120.png')}
								title="first page"
								alt="pic"
							/>
						</div>
					</Grid>
					<Grid item xs={8}>
						<div className="main-content-success">
							<div className="wrapper-success" dir={direction}>
								<div className="image-last-page">
									{/* no needed */}
									{/* <img
                    src={require("../assets/unilogo-60x60.png")}
                    alt="logo"
                    width="60"
                    height="60"
                  /> */}
								</div>

								<div className="image-success">
									<img src={require('../assets/success.svg')} alt="logo" width="100" height="100" />
								</div>

								<StyledTitle
									variant="h6"
									display="block"
									className="title-success"
									gutterBottom
									style={{
										textAlign: 'center',
										color: '#32C6A9',
										fontSize: '24px'
									}}
								>
									{textData.thankyou}
								</StyledTitle>

								<div className="text-success">
									<p>
										{breakLine(textData.text.replace(/\n/g, '<br />'))}
										<br />
										<br />{' '}
										<a
											href={'https://www.youtube.com/watch?v=00uRxBz115I&feature=youtu.be'}
											target="_blank"
											rel="noopener noreferrer"
										>
											how to use Class.Me Application
										</a>{' '}
										<br />
										<br />
										{/* <p>talk to us on Whatsapp!</p>
                    <a href={
                        "https://wa.me/972528808558/?text=%D7%90%D7%94%D7%9C%D7%9F%20%D7%92%D7%9D%20%D7%90%D7%A0%D7%99%20%D7%A0%D7%A8%D7%A9%D7%9E%D7%AA%D7%99%20%D7%9C%D7%90%D7%A7%D7%93%D7%9E%D7%99%D7%94%20%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C%D7%99%D7%AA%20%D7%91%20UniMastery%0A%D7%99%D7%A9%20%D7%9C%D7%99%20%D7%A9%D7%90%D7%9C%D7%94%2F%D7%91%D7%A7%D7%A9%D7%94...%20%D7%90%D7%A0%D7%90%20%D7%A6%D7%A8%D7%95%20%D7%A2%D7%99%D7%9E%D7%99%20%D7%A7%D7%A9%D7%A8%20%D7%91%D7%94%D7%A7%D7%93%D7%9D"
                      }
                    >
                      <button className="share-whatsapp">
                        text us
                  <img
                          src={require("../assets/whatsapp-icon.svg")}
                          style={{ marginRight: "20px", verticalAlign: "middle" }}
                          alt="logo"
                        />
                      </button>
                    </a> */}
									</p>
								</div>
                <br />
										<br />
								<div className='share_button_and_text_wrapper'>
									<div style={{textAlign: "center"}}>
										<p>Talk to us on Whatsapp!</p>
										<a
											href={
												'https://wa.me/972528808558/?text=%D7%90%D7%94%D7%9C%D7%9F%20%D7%92%D7%9D%20%D7%90%D7%A0%D7%99%20%D7%A0%D7%A8%D7%A9%D7%9E%D7%AA%D7%99%20%D7%9C%D7%90%D7%A7%D7%93%D7%9E%D7%99%D7%94%20%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C%D7%99%D7%AA%20%D7%91%20UniMastery%0A%D7%99%D7%A9%20%D7%9C%D7%99%20%D7%A9%D7%90%D7%9C%D7%94%2F%D7%91%D7%A7%D7%A9%D7%94...%20%D7%90%D7%A0%D7%90%20%D7%A6%D7%A8%D7%95%20%D7%A2%D7%99%D7%9E%D7%99%20%D7%A7%D7%A9%D7%A8%20%D7%91%D7%94%D7%A7%D7%93%D7%9D'
											}
										>
											<button className="share-whatsapp">
												Text us &nbsp;
												<img
													src={require('../assets/whatsapp-icon.svg')}
													style={{ marginRight: '20px', verticalAlign: 'middle' }}
													alt="logo"
												/>
											</button>
										</a>
									</div>
                  <div className="share-buttons-wrapper">
									<div className="text-success-center">
										<p>spread the word!</p>
									</div>

									<div className="share-buttons">
										<a
											href="https://twitter.com/share?url=https://class.me"
											onClick="popUp=window.open(this.href, 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false"
										>
											<button className="share-twitter share-btns">
												Twitter &nbsp;
												<img
													src={require('../assets/twitter-bird.svg')}
													style={{ marginRight: '20px', verticalAlign: 'middle' }}
													alt="logo"
												/>
											</button>
										</a>

										<a
											href="https://www.facebook.com/sharer/sharer.php?u=https://class.me"
											className="fb-xfbml-parse-ignore"
										>
											<button className="share-facebook share-btns">
												Facebook &nbsp;
												<img
													src={require('../assets/facebook-f.svg')}
													style={{ marginRight: '20px', verticalAlign: 'middle' }}
													alt="logo"
												/>
											</button>
										</a>
									</div>
								</div>
									<div />
								</div>

								{/* <div className="share-buttons-wrapper">
									<div className="text-success-center">
										<p>spread the word!</p>
									</div>

									<div className="share-buttons">
										<a
											href="https://twitter.com/share?url=https://class.me"
											onClick="popUp=window.open(this.href, 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false"
										>
											<button className="share-twitter share-btns">
												Twitter
												<img
													src={require('../assets/twitter-bird.svg')}
													style={{ marginRight: '20px', verticalAlign: 'middle' }}
													alt="logo"
												/>
											</button>
										</a>

										<a
											href="https://www.facebook.com/sharer/sharer.php?u=https://class.me"
											className="fb-xfbml-parse-ignore"
										>
											<button className="share-facebook share-btns">
												Facebook
												<img
													src={require('../assets/facebook-f.svg')}
													style={{ marginRight: '20px', verticalAlign: 'middle' }}
													alt="logo"
												/>
											</button>
										</a>
									</div>
								</div> */}
							</div>
						</div>

            {direction === 'ltr' ?        
							<div className="contact-info-div">
								<p className="contact-text-bottom" variant="caption" display="block">
									for any questions please contact us at
									<br />
									טלפון: <a href={'tel:052-8808558'}>052-8808558</a> Email:{' '}
									<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
								</p>
								<p className="terms">
									<br />
									<a href={'https://class.me/termsHE.html'}>Terms of use and privacy policy</a>
								</p>
							</div> 
              
              : // or (if not english)

                      // if hebrew
              	<div className="contact-info-div">
								<p className="contact-text-bottom" variant="caption" display="block">
                לכל שאלה ובקשה ניתן לשלוח דוא"ל לכתובת
									<br />
									טלפון: <a href={'tel:052-8808558'}>052-8808558</a> מייל:{' '}
									<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
								</p>
								<p className="terms">
									<br />
									<a href={'https://class.me/termsHE.html'}>תנאי שימוש ומדיניות פרטיות</a>
								</p>
							</div>
             }
						{/* <div className="contact-info-div">
							<p className="contact-text-bottom" variant="caption" display="block">
								for any questions please contact us at
								<br />
								טלפון: <a href={'tel:052-8808558'}>052-8808558</a> מייל:{' '}
								<a href={'mailto:contact@class.me'}>contact@class.me</a>
							</p>
							<p className="terms">
								<br />
								<a href={'https://class.me/termsHE.html'}>Terms of Service and Privacy Policy</a>
							</p>
						</div> */}
					</Grid>
					<Grid item xs={2}>
						<div className="white-space-side" />
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default Success;
