/**
 * @description Project wide constants file
 */

// released app version - for versionning only, no usage in code
const version = "3.2.3 - PROD - INT. PHONE CODE"
// Payment
export const PAYPAL_PROCESSOR = "PAYPAL";
export const PAYPAL_CLIENT_ID = "AbNtbfPfbCdH41sgO7pU1qpAihE9F9lpndhXUCZJINHh4MA77lct-joyLGKu27EwA8vO8buGXW3--CQ3";
export const PAYPAL_SECRET = "EI2KiZKUCue9ESH7giovWj29-lNpajEDhgRhxSH4xtlYhtr6R0RU-C0XKlRt5lBbKJwrbOj3i9tu0nmT";
export const PaypalOneTimePayment = 0;
export const PaypalSubscriptionPayment = 1;