import React, { Component } from 'react';
import * as Constants from '../constants';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import Success from './Success';

import '../styles/main.css';
import '../styles/overrideMaterialUI.css';

import { parsePhoneNumberFromString as format } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';


import { parseNumber as parseNumber } from 'libphonenumber-js';


import reloadPayPalScript from './reloadPayPalScript';
const countryListAllIsoData = [{"country":"Afghanistan","code":"93","iso":"AF"},
{"country":"Albania","code":"355","iso":"AL"},
{"country":"Algeria","code":"213","iso":"DZ"},
{"country":"American Samoa","code":"1-684","iso":"AS"},
{"country":"Andorra","code":"376","iso":"AD"},
{"country":"Angola","code":"244","iso":"AO"},
{"country":"Anguilla","code":"1-264","iso":"AI"},
{"country":"Antarctica","code":"672","iso":"AQ"},
{"country":"Antigua and Barbuda","code":"1-268","iso":"AG"},
{"country":"Argentina","code":"54","iso":"AR"},
{"country":"Armenia","code":"374","iso":"AM"},
{"country":"Aruba","code":"297","iso":"AW"},
{"country":"Australia","code":"61","iso":"AU"},
{"country":"Austria","code":"43","iso":"AT"},
{"country":"Azerbaijan","code":"994","iso":"AZ"},
{"country":"Bahamas","code":"1-242","iso":"BS"},
{"country":"Bahrain","code":"973","iso":"BH"},
{"country":"Bangladesh","code":"880","iso":"BD"},
{"country":"Barbados","code":"1-246","iso":"BB"},
{"country":"Belarus","code":"375","iso":"BY"},
{"country":"Belgium","code":"32","iso":"BE"},
{"country":"Belize","code":"501","iso":"BZ"},
{"country":"Benin","code":"229","iso":"BJ"},
{"country":"Bermuda","code":"1-441","iso":"BM"},
{"country":"Bhutan","code":"975","iso":"BT"},
{"country":"Bolivia","code":"591","iso":"BO"},
{"country":"Bosnia and Herzegovina","code":"387","iso":"BA"},
{"country":"Botswana","code":"267","iso":"BW"},
{"country":"Brazil","code":"55","iso":"BR"},
{"country":"British Indian Ocean Territory","code":"246","iso":"IO"},
{"country":"British Virgin Islands","code":"1-284","iso":"VG"},
{"country":"Brunei","code":"673","iso":"BN"},
{"country":"Bulgaria","code":"359","iso":"BG"},
{"country":"Burkina Faso","code":"226","iso":"BF"},
{"country":"Burundi","code":"257","iso":"BI"},
{"country":"Cambodia","code":"855","iso":"KH"},
{"country":"Cameroon","code":"237","iso":"CM"},
{"country":"Canada","code":"1","iso":"CA"},
{"country":"Cape Verde","code":"238","iso":"CV"},
{"country":"Cayman Islands","code":"1-345","iso":"KY"},
{"country":"Central African Republic","code":"236","iso":"CF"},
{"country":"Chad","code":"235","iso":"TD"},
{"country":"Chile","code":"56","iso":"CL"},
{"country":"China","code":"86","iso":"CN"},
{"country":"Christmas Island","code":"61","iso":"CX"},
{"country":"Cocos Islands","code":"61","iso":"CC"},
{"country":"Colombia","code":"57","iso":"CO"},
{"country":"Comoros","code":"269","iso":"KM"},
{"country":"Cook Islands","code":"682","iso":"CK"},
{"country":"Costa Rica","code":"506","iso":"CR"},
{"country":"Croatia","code":"385","iso":"HR"},
{"country":"Cuba","code":"53","iso":"CU"},
{"country":"Curacao","code":"599","iso":"CW"},
{"country":"Cyprus","code":"357","iso":"CY"},
{"country":"Czech Republic","code":"420","iso":"CZ"},
{"country":"Democratic Republic of the Congo","code":"243","iso":"CD"},
{"country":"Denmark","code":"45","iso":"DK"},
{"country":"Djibouti","code":"253","iso":"DJ"},
{"country":"Dominica","code":"1-767","iso":"DM"},
{"country":"Dominican Republic","code":"1-809, 1-829, 1-849","iso":"DO"},
{"country":"East Timor","code":"670","iso":"TL"},
{"country":"Ecuador","code":"593","iso":"EC"},
{"country":"Egypt","code":"20","iso":"EG"},
{"country":"El Salvador","code":"503","iso":"SV"},
{"country":"Equatorial Guinea","code":"240","iso":"GQ"},
{"country":"Eritrea","code":"291","iso":"ER"},
{"country":"Estonia","code":"372","iso":"EE"},
{"country":"Ethiopia","code":"251","iso":"ET"},
{"country":"Falkland Islands","code":"500","iso":"FK"},
{"country":"Faroe Islands","code":"298","iso":"FO"},
{"country":"Fiji","code":"679","iso":"FJ"},
{"country":"Finland","code":"358","iso":"FI"},
{"country":"France","code":"33","iso":"FR"},
{"country":"French Polynesia","code":"689","iso":"PF"},
{"country":"Gabon","code":"241","iso":"GA"},
{"country":"Gambia","code":"220","iso":"GM"},
{"country":"Georgia","code":"995","iso":"GE"},
{"country":"Germany","code":"49","iso":"DE"},
{"country":"Ghana","code":"233","iso":"GH"},
{"country":"Gibraltar","code":"350","iso":"GI"},
{"country":"Greece","code":"30","iso":"GR"},
{"country":"Greenland","code":"299","iso":"GL"},
{"country":"Grenada","code":"1-473","iso":"GD"},
{"country":"Guam","code":"1-671","iso":"GU"},
{"country":"Guatemala","code":"502","iso":"GT"},
{"country":"Guernsey","code":"44-1481","iso":"GG"},
{"country":"Guinea","code":"224","iso":"GN"},
{"country":"Guinea-Bissau","code":"245","iso":"GW"},
{"country":"Guyana","code":"592","iso":"GY"},
{"country":"Haiti","code":"509","iso":"HT"},
{"country":"Honduras","code":"504","iso":"HN"},
{"country":"Hong Kong","code":"852","iso":"HK"},
{"country":"Hungary","code":"36","iso":"HU"},
{"country":"Iceland","code":"354","iso":"IS"},
{"country":"India","code":"91","iso":"IN"},
{"country":"Indonesia","code":"62","iso":"ID"},
{"country":"Iran","code":"98","iso":"IR"},
{"country":"Iraq","code":"964","iso":"IQ"},
{"country":"Ireland","code":"353","iso":"IE"},
{"country":"Isle of Man","code":"44-1624","iso":"IM"},
{"country":"Israel","code":"972","iso":"IL"},
{"country":"Italy","code":"39","iso":"IT"},
{"country":"Ivory Coast","code":"225","iso":"CI"},
{"country":"Jamaica","code":"1-876","iso":"JM"},
{"country":"Japan","code":"81","iso":"JP"},
{"country":"Jersey","code":"44-1534","iso":"JE"},
{"country":"Jordan","code":"962","iso":"JO"},
{"country":"Kazakhstan","code":"7","iso":"KZ"},
{"country":"Kenya","code":"254","iso":"KE"},
{"country":"Kiribati","code":"686","iso":"KI"},
{"country":"Kosovo","code":"383","iso":"XK"},
{"country":"Kuwait","code":"965","iso":"KW"},
{"country":"Kyrgyzstan","code":"996","iso":"KG"},
{"country":"Laos","code":"856","iso":"LA"},
{"country":"Latvia","code":"371","iso":"LV"},
{"country":"Lebanon","code":"961","iso":"LB"},
{"country":"Lesotho","code":"266","iso":"LS"},
{"country":"Liberia","code":"231","iso":"LR"},
{"country":"Libya","code":"218","iso":"LY"},
{"country":"Liechtenstein","code":"423","iso":"LI"},
{"country":"Lithuania","code":"370","iso":"LT"},
{"country":"Luxembourg","code":"352","iso":"LU"},
{"country":"Macao","code":"853","iso":"MO"},
{"country":"Macedonia","code":"389","iso":"MK"},
{"country":"Madagascar","code":"261","iso":"MG"},
{"country":"Malawi","code":"265","iso":"MW"},
{"country":"Malaysia","code":"60","iso":"MY"},
{"country":"Maldives","code":"960","iso":"MV"},
{"country":"Mali","code":"223","iso":"ML"},
{"country":"Malta","code":"356","iso":"MT"},
{"country":"Marshall Islands","code":"692","iso":"MH"},
{"country":"Mauritania","code":"222","iso":"MR"},
{"country":"Mauritius","code":"230","iso":"MU"},
{"country":"Mayotte","code":"262","iso":"YT"},
{"country":"Mexico","code":"52","iso":"MX"},
{"country":"Micronesia","code":"691","iso":"FM"},
{"country":"Moldova","code":"373","iso":"MD"},
{"country":"Monaco","code":"377","iso":"MC"},
{"country":"Mongolia","code":"976","iso":"MN"},
{"country":"Montenegro","code":"382","iso":"ME"},
{"country":"Montserrat","code":"1-664","iso":"MS"},
{"country":"Morocco","code":"212","iso":"MA"},
{"country":"Mozambique","code":"258","iso":"MZ"},
{"country":"Myanmar","code":"95","iso":"MM"},
{"country":"Namibia","code":"264","iso":"NA"},
{"country":"Nauru","code":"674","iso":"NR"},
{"country":"Nepal","code":"977","iso":"NP"},
{"country":"Netherlands","code":"31","iso":"NL"},
{"country":"Netherlands Antilles","code":"599","iso":"AN"},
{"country":"New Caledonia","code":"687","iso":"NC"},
{"country":"New Zealand","code":"64","iso":"NZ"},
{"country":"Nicaragua","code":"505","iso":"NI"},
{"country":"Niger","code":"227","iso":"NE"},
{"country":"Nigeria","code":"234","iso":"NG"},
{"country":"Niue","code":"683","iso":"NU"},
{"country":"North Korea","code":"850","iso":"KP"},
{"country":"Northern Mariana Islands","code":"1-670","iso":"MP"},
{"country":"Norway","code":"47","iso":"NO"},
{"country":"Oman","code":"968","iso":"OM"},
{"country":"Pakistan","code":"92","iso":"PK"},
{"country":"Palau","code":"680","iso":"PW"},
{"country":"Palestine","code":"970","iso":"PS"},
{"country":"Panama","code":"507","iso":"PA"},
{"country":"Papua New Guinea","code":"675","iso":"PG"},
{"country":"Paraguay","code":"595","iso":"PY"},
{"country":"Peru","code":"51","iso":"PE"},
{"country":"Philippines","code":"63","iso":"PH"},
{"country":"Pitcairn","code":"64","iso":"PN"},
{"country":"Poland","code":"48","iso":"PL"},
{"country":"Portugal","code":"351","iso":"PT"},
{"country":"Puerto Rico","code":"1-787, 1-939","iso":"PR"},
{"country":"Qatar","code":"974","iso":"QA"},
{"country":"Republic of the Congo","code":"242","iso":"CG"},
{"country":"Reunion","code":"262","iso":"RE"},
{"country":"Romania","code":"40","iso":"RO"},
{"country":"Russia","code":"7","iso":"RU"},
{"country":"Rwanda","code":"250","iso":"RW"},
{"country":"Saint Barthelemy","code":"590","iso":"BL"},
{"country":"Saint Helena","code":"290","iso":"SH"},
{"country":"Saint Kitts and Nevis","code":"1-869","iso":"KN"},
{"country":"Saint Lucia","code":"1-758","iso":"LC"},
{"country":"Saint Martin","code":"590","iso":"MF"},
{"country":"Saint Pierre and Miquelon","code":"508","iso":"PM"},
{"country":"Saint Vincent and the Grenadines","code":"1-784","iso":"VC"},
{"country":"Samoa","code":"685","iso":"WS"},
{"country":"San Marino","code":"378","iso":"SM"},
{"country":"Sao Tome and Principe","code":"239","iso":"ST"},
{"country":"Saudi Arabia","code":"966","iso":"SA"},
{"country":"Senegal","code":"221","iso":"SN"},
{"country":"Serbia","code":"381","iso":"RS"},
{"country":"Seychelles","code":"248","iso":"SC"},
{"country":"Sierra Leone","code":"232","iso":"SL"},
{"country":"Singapore","code":"65","iso":"SG"},
{"country":"Sint Maarten","code":"1-721","iso":"SX"},
{"country":"Slovakia","code":"421","iso":"SK"},
{"country":"Slovenia","code":"386","iso":"SI"},
{"country":"Solomon Islands","code":"677","iso":"SB"},
{"country":"Somalia","code":"252","iso":"SO"},
{"country":"South Africa","code":"27","iso":"ZA"},
{"country":"South Korea","code":"82","iso":"KR"},
{"country":"South Sudan","code":"211","iso":"SS"},
{"country":"Spain","code":"34","iso":"ES"},
{"country":"Sri Lanka","code":"94","iso":"LK"},
{"country":"Sudan","code":"249","iso":"SD"},
{"country":"Suriname","code":"597","iso":"SR"},
{"country":"Svalbard and Jan Mayen","code":"47","iso":"SJ"},
{"country":"Swaziland","code":"268","iso":"SZ"},
{"country":"Sweden","code":"46","iso":"SE"},
{"country":"Switzerland","code":"41","iso":"CH"},
{"country":"Syria","code":"963","iso":"SY"},
{"country":"Taiwan","code":"886","iso":"TW"},
{"country":"Tajikistan","code":"992","iso":"TJ"},
{"country":"Tanzania","code":"255","iso":"TZ"},
{"country":"Thailand","code":"66","iso":"TH"},
{"country":"Togo","code":"228","iso":"TG"},
{"country":"Tokelau","code":"690","iso":"TK"},
{"country":"Tonga","code":"676","iso":"TO"},
{"country":"Trinidad and Tobago","code":"1-868","iso":"TT"},
{"country":"Tunisia","code":"216","iso":"TN"},
{"country":"Turkey","code":"90","iso":"TR"},
{"country":"Turkmenistan","code":"993","iso":"TM"},
{"country":"Turks and Caicos Islands","code":"1-649","iso":"TC"},
{"country":"Tuvalu","code":"688","iso":"TV"},
{"country":"U.S. Virgin Islands","code":"1-340","iso":"VI"},
{"country":"Uganda","code":"256","iso":"UG"},
{"country":"Ukraine","code":"380","iso":"UA"},
{"country":"United Arab Emirates","code":"971","iso":"AE"},
{"country":"United Kingdom","code":"44","iso":"GB"},
{"country":"United States","code":"1","iso":"US"},
{"country":"Uruguay","code":"598","iso":"UY"},
{"country":"Uzbekistan","code":"998","iso":"UZ"},
{"country":"Vanuatu","code":"678","iso":"VU"},
{"country":"Vatican","code":"379","iso":"VA"},
{"country":"Venezuela","code":"58","iso":"VE"},
{"country":"Vietnam","code":"84","iso":"VN"},
{"country":"Wallis and Futuna","code":"681","iso":"WF"},
{"country":"Western Sahara","code":"212","iso":"EH"},
{"country":"Yemen","code":"967","iso":"YE"},
{"country":"Zambia","code":"260","iso":"ZM"},
{"country":"Zimbabwe","code":"263","iso":"ZW"}]

// URL PARAMETERS
let search = window.location.search;
let params = new URLSearchParams(search);
let codeCoupon = params.get('code');
let successParam = params.get('success');
const userName = params.get('name');
const mobileNumber = params.get('mobile');
const receiptEmail = params.get('email');
const expiredClassId = params.get('expired_classid');

// CONSTATNS
const pathName = window.location.pathname;
const noPaymentStr = 'free';

let currentPaypalPaymentMethod = Constants.PaypalOneTimePayment;
let paypal_order_id = '';
let paypal_amount = '';

export class Finals extends Component {
	state = {
		step: successParam ? 3 : 0,

		totalSteps: 1,
		selectedCourseData: '',
		courseTitles: [],
		clssesSubclassesValues: {},
		classSelection: '',
		courses: [],
		optionalGrades: [],
		classSubSelection: '',
		fullName: userName,
		phoneNumber: mobileNumber,
		countryCode: 'IL',
		validPhoneNumber: '',
		invalidPhone: true,
		emailAddress: receiptEmail,
		validEmailAddress: '',
		invalidEmail: false,
		subjectPath: '',
		courseSelectedOffset: '0',
		language: '',
		classRadio: '',
		couponCode: codeCoupon || '',
		couponGroups: [],
		tempPriceCombination: !codeCoupon ? 0 : void 0,
		priceCombination: void 0,
		couponError: false,
		openMessageModal: this.couponError ? true : false,
		success: successParam,
		tempClassesArray: [],
		sameClassSubclasses: [],
		enableAddNewClass: false,
		enableRemoveClass: false,
		showLoader: true,
		city: '',
		citySelection: null,

		isUserPaying: false,
		paymentProcessor: 'CG',
		currency: 'ILS',
		canChooseGender: false,
		canChooseLanguage: false,
		trialData: {},
		successData: {},
		pricePerClass: 0,
		totalPrice: 0,
		maxNumberOfInstallments: 0,
		schoolInput: '',
		validSchool: '',
		invalidSchoolname: false,
		cancelDays: 0,
		showTrialPeriod: false,
		paymentIframeSrc: '',
		showGradeList: true,
		customerFieldLabel: null,
		customerFieldValue: null,
		constantSchool: null,
		course_selection_field_label: null,
		numberOfPayments: -1,
		subsctiptionPlanId: ''
	};

	componentWillMount() {
		if (this.state.step === 0) {
			//this.cancel();
			this.getCustomData(this.getCustomerParam());
			console.log('BEFORE price is: ' + this.state);
			// this.state.price = this.state.courses[0].price;
			// console.log("AFTER price is: " + this.state.price);
		}
	}

	getCustomerParam = () => {
		const pathSlices = pathName.split('/');
		if (pathSlices.length > 1) {
			return pathSlices[1];
		}
		return null;
	};
	componentDidMount() {
		//this.addInitialClassObject();
	}

	// fetch classes json for given customer
	getCustomData = async (customer) => {
		console.log(customer);
		const url = `https://us-central1-classme-150316.cloudfunctions.net/getLearningCenterData?customer=${customer}`;
		fetch(url)
			.then((resp) => resp.json())
			.then((res) => {
				console.log('data from sheet: ', res);
				if (res !== null && res !== '') {
					const isExpirationPage = expiredClassId ? true : false;
					let classes = res.classes;
					// if it's an expiration link to payment page, keep only expired class
					if (isExpirationPage) {	
						classes = res.classes.filter(function(value, index, arr) {
							return value.classId === expiredClassId;
						});
					}
					// sanity check - if a wrong expired classid passes, display all the existing classess
					if (classes.length === 0) {
						classes = res.classes;
					}

					var tmpTitles = [];
					for (let i = 0; i < classes.length; i++) {
						tmpTitles.push(classes[i].title);
					}
					console.log('language is:' + res.language);
					this.setState({
						optionalGrades: res.grades,
						showGradeList: res.show_grade_list ? res.show_grade_list : false,
						courseTitles: tmpTitles,
						courses: classes,
						customerFieldLabel: res.customer_field_label,
						customerFieldValue: res.constant_city,
						course_selection_field_label: res.course_selection_field_label,
						constantSchool: res.constant_school,
						isUserPaying: res.is_paying,
						paymentProcessor: res.payment_processor ? res.payment_processor : 'CG',
						currency: res.currency,
						canChooseGender: res.can_choose_gender,
						canChooseLanguage: res.can_choose_language,
						language: res.language,
						pricePerClass: res.price,
						totalSteps: res.is_paying ? 3 : 2,
						trialData: res.trial,
						cancelDays: res.trial.days,
						successData: res.success,
						totalPrice: res.classes[0].price,
						numberOfPayments: res.classes[0].registration_period,
						subsctiptionPlanId: res.classes[0].subsctiptionPlanId,
						maxNumberOfInstallments: res.classes[0].max_installments
					});
					// this.addInitialClassObject(res.classes);
					const t = 4;
				}
			})
			.catch((reason) => {
				console.log(reason);
			});
	};

	removeClassObject = (index) => {
		let classItems = [ ...this.state.tempClassesArray ];

		if (index > 0) {
			classItems.splice(index, 1);
			this.setState({
				tempClassesArray: classItems,
				enableAddNewClass: true
			});
		}
	};

	// Proceed to next step
	nextStep = () => {
		const { step } = this.state;
		console.log('state on nextstep is:', this.state);
		if (step < this.state.totalSteps - 1) {
			this.finishPage(step + 1);
		}
		let nextStep = step + 1;
		if (
			nextStep === 2 &&
			(this.state.totalPrice <= 0 || this.state.paymentProcessor === Constants.PAYPAL_PROCESSOR)
		) {
			nextStep++;
		}
		this.setState({
			step: nextStep
		});
	};

	// Go back to prev step
	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
	};

	// Handle fields change
	handleChangeName = (e) => {
		this.setState({ fullName: e.target.value });
	};

	
	handleChangePhoneNumber = async (e) => {
		// phone number change
		const userPhoneInput = e.target.value;
		this.setState({
		  phoneNumber: userPhoneInput,
		});
			
		const countyCode = this.state.countryCode;
		const formattedPhone = format(userPhoneInput, countyCode);

		if (formattedPhone) {
			if (formattedPhone.isValid()) {
			this.setState({
				validPhoneNumber: formattedPhone.number,
				invalidPhone: false,
			});
			} else {
			this.setState({
				invalidPhone: true,
			});
			}
		} else {
		this.setState({
			invalidPhone: true,
			});
		}
	  };
	

	// payer email address
	handleChangeEmailAddress = (e) => {
		let invalidMail = true;
		let emailAddress = e.target.value;
		let validEmailAddress = '';
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(emailAddress).toLowerCase())) {
			invalidMail = false;
			validEmailAddress = emailAddress;
		}

		this.setState({
			invalidEmail: invalidMail,
			emailAddress: emailAddress,
			validEmailAddress: validEmailAddress
		});
	};

	// First page city
	handleCityValues = async (input) => {
		this.setState({
			citySelection: input,
			city: input.city_name
		});
	};
	// First page school
	handleSchool = async (e) => {
		this.setState({
			schoolInput: e.target.value
		});
		if (this.state.schoolInput.length > 2 && this.state.schoolInput.length < 31) {
			this.setState({
				validSchool: this.state.schoolInput,
				invalidSchoolname: false
			});
		} else {
			this.setState({
				invalidSchoolname: true
			});
		}
	};

	handleChangeCourse = (e) => {
		console.log('target value is:', e.target.value);
		const selectdCourseIndex = parseInt(e.target.value);
		console.log('handleChangeCourse-> selectdCourseIndex is: ' + selectdCourseIndex);
		console.log('handleChangeCourse-> price  is: ' + this.state.courses[selectdCourseIndex].price);
		//this.updatePayPalScript(this.state.courses[selectdCourseIndex].registration_period);
		console.log('payment processor is: ' + this.state.paymentProcessor);
		if (this.state.paymentProcessor === Constants.PAYPAL_PROCESSOR) {
			this.updatePayPalScript(this.state.courses[selectdCourseIndex].registration_period);
		}

		this.setState({
			courseSelectedOffset: selectdCourseIndex,
			totalPrice: this.state.courses[selectdCourseIndex].price,
			numberOfPayments: this.state.courses[selectdCourseIndex].registration_period,
			is1on1Class: this.state.courses[selectdCourseIndex].is1on1Class,
			maxNumberOfInstallments: this.state.courses[selectdCourseIndex].max_installments,
			subsctiptionPlanId: this.state.courses[selectdCourseIndex].subsctiptionPlanId
		});
		console.log('saved course data is: ', this.state.courses[e.target.value]);
	};

	// class sublist second dropdown
	handleChangeSubcourseTitles = async (e, index) => {
		const classItems = [ ...this.state.tempClassesArray ];

		let objToChange = { ...classItems[index] };
		objToChange.classSubSelection = e.target.value;

		classItems[index] = objToChange;
		this.setState({
			tempClassesArray: classItems
		});

		let classSubclasses = [ ...this.state.sameClassSubclasses ];
		classSubclasses.push(objToChange.classSubSelection);
		this.setState({
			sameClassSubclasses: classSubclasses,
			enableAddNewClass: true
		});
	};

	// wrong name, this is actually changing the country code
	handleChangeLanguage = async (e) => {

		// phone number change
		const userPhoneInput = e;
		this.setState({
		  phoneNumber: userPhoneInput,
		});
			
		const formattedPhone = parsePhoneNumber("+"+e)

		if (formattedPhone) {
			if (formattedPhone.isValid()) {
			this.setState({
				validPhoneNumber: formattedPhone.number,
				invalidPhone: false,
			});
			} else {
			this.setState({
				invalidPhone: true,
			});
			}
		} else {
		this.setState({
			invalidPhone: true,
			});
		}


		console.log("country code is:"+this.state.countryCode);

	};

	handleChangeClassRadio = (e) => {
		this.setState({
			classRadio: e.target.value
		});
	};

	handleModalClose = () => {
		this.setState({
			openMessageModal: false
		});
	};

	onPayPalPaymentSuccess(order_id, value) {
		paypal_order_id = order_id;
		paypal_amount = value;

		console.log(
			'--------------- PAYPAL SUCCESS INFO -----------------' + 'order id: ' + order_id + 'amount: ' + value
		);
	}

	finish = () => {
		this.finishPage();
	};

	// finish step where all data is sent to an API
	finishPage = async (pageNum) => {
		console.log('---------------- FINISH PAGE', pageNum, paypal_order_id, paypal_amount);
		let allValues;
		// To retrive that item
		try {
			const serializedState = localStorage.getItem('state');

			if (serializedState === null) {
				return undefined;
			}
			allValues = JSON.parse(serializedState);
		} catch (err) {
			allValues = undefined;
		}

		const {
			fullName,
			validPhoneNumber,
			countryCode,
			validEmailAddress,
			city,
			validSchool,
			courseSelectedOffset,
			language,
			classRadio,
			couponCode,
			showTrialPeriod,
			clssesSubclassesValues,
			courses,
			totalPrice,
			numberOfPayments,
			currency,
			maxNumberOfInstallments,
			subsctiptionPlanId,
			paymentProcessor
		} = allValues;
		const finishValues = {
			fullName,
			validPhoneNumber,
			countryCode,
			city,
			validEmailAddress,
			validSchool,
			courseSelectedOffset,
			language,
			classRadio,
			couponCode,
			showTrialPeriod,
			clssesSubclassesValues,
			courses,
			totalPrice,
			numberOfPayments,
			currency,
			maxNumberOfInstallments,
			subsctiptionPlanId,
			paymentProcessor
		};

		let selctedCourseDataStr = '';
		console.log('*********this.state.courseSelectedOffset:', this.state.courseSelectedOffset);
		console.log('*********finishValues.courseSelectedOffset:', finishValues.courseSelectedOffset);
		const currentCourseSelectedOffset =
			this.state.paymentProcessor === Constants.PAYPAL_PROCESSOR
				? this.state.courseSelectedOffset
				: finishValues.courseSelectedOffset;
		if (!currentCourseSelectedOffset) {
			selctedCourseDataStr = `subject=${''}&level=""&classId=${''}&`;
		} else {
			console.log('finishValues.courses:', finishValues.courses);
			console.log('finishValues.:', finishValues);
			console.log('currentCourseSelectedOffset:', currentCourseSelectedOffset);
			console.log('info: ', finishValues.courses[parseInt(currentCourseSelectedOffset)]);
			selctedCourseDataStr = `subject=${finishValues.courses[parseInt(currentCourseSelectedOffset)].title}&\
level=""&classId=${finishValues.courses[parseInt(currentCourseSelectedOffset)].classId}&\
is1on1Class=${finishValues.courses[parseInt(currentCourseSelectedOffset)].is1on1Class}&`;
		}
		// dev - lior comment out when deploying prod
		//const apiUrl =   "https://script.google.com/macros/s/AKfycbxaLUQQccPddSHaNWuvaNuoHc329lUNQDf4uQDA_XIc/dev";

		// prod:
		const apiUrl =
			'https://script.google.com/macros/s/AKfycbw-u08MlC2cIbYSQIWPrpqgmoY2Zt8GeY7Akw66KKBsgCEzJnKEBzlDvxJIbi3rUl6X/exec';
		const txId =
			this.state.paymentProcessor === Constants.PAYPAL_PROCESSOR ? paypal_order_id : this.getQueryParam('txId');
		const transactionId = totalPrice > 0 ? txId : noPaymentStr;

		let parameters = `fullName=${fullName}&\
userMobile=${validPhoneNumber}&\
receiptEmail=${validEmailAddress}&\
city=${city}&\
school=${validSchool}&\
gradeLevel=${''}&\
gender=${finishValues.classRadio}&\
language=${finishValues.language}&\
trialOn=${showTrialPeriod}&\
customer=${this.getCustomerParam()}&\
paymentProcessor=${finishValues.paymentProcessor}&\
paymentTxId=${transactionId}&\
paymentAmount=${totalPrice}&\
numOfPayments=${finishValues.numberOfPayments}&\
page=${pageNum}&`;
		console.log('apiUrl is:', apiUrl);
		parameters += selctedCourseDataStr;
		const url = apiUrl + '?' + parameters;
		console.log('script url is:', url);
		try {
			const resp = await fetch(url);
			const res = await resp.text();
			console.log(res);
		} catch (err) {
			console.error(err);
		}

		//  localStorage.clear();
	};

	cancel = () => {
		this.setState({
			step: 0,
			fullName: '',
			phoneNumber: '',
			emailAddress: '',
			classSelection: '',
			city: '',
			validSchool: '',
			courseSelectedOffset: '',
			language: '',
			classRadio: '',
			classSubSelection: '',
			couponInput: codeCoupon || '',
			couponCode: codeCoupon || ''
		});
		localStorage.clear();
	};

	getQueryParam = (requestedParam) => {
		const pathParams = window.location.search;
		console.log(pathParams);
		const queryParams = pathParams.split('?')[pathParams.split('?').length - 1];
		console.log(queryParams);
		if (queryParams) {
			const paramsObj = {};
			for (const paramPair of queryParams.split('&')) {
				const keyValueArray = paramPair.split('=');
				if (keyValueArray.length > 1) paramsObj[keyValueArray[0]] = keyValueArray[1];
			}
			console.log(paramsObj);
			return paramsObj[requestedParam];
		}
	};

	saveToLocalStorage = () => {
		const {
			fullName,
			validPhoneNumber,
			countryCode,
			emailAddress,
			validEmailAddress,
			city,
			validSchool,
			courseSelectedOffset,
			language,
			classRadio,
			couponCode,
			showTrialPeriod,
			clssesSubclassesValues,
			successData,
			courses,
			totalPrice,
			numberOfPayments,
			currency,
			maxNumberOfInstallments,
			subsctiptionPlanId,
			paymentProcessor
		} = this.state;
		const allValues = {
			fullName,
			validPhoneNumber,
			countryCode,
			emailAddress,
			validEmailAddress,
			city,
			validSchool,
			courseSelectedOffset,
			language,
			classRadio,
			couponCode,
			showTrialPeriod,
			clssesSubclassesValues,
			successData,
			courses,
			totalPrice,
			numberOfPayments,
			currency,
			maxNumberOfInstallments,
			subsctiptionPlanId,
			paymentProcessor
		};
		// To save the item
		try {
			const serializedState = JSON.stringify(allValues);
			localStorage.setItem('state', serializedState);
		} catch (err) {
			return undefined;
		}
	};

	hideLoader = () => {
		this.setState({
			showLoader: false
		});
	};

	handleCheckboxTrial = () => {
		this.setState({
			showTrialPeriod: !this.state.showTrialPeriod
		});
	};

	updatePayPalScript = (registration_period) => {
		// reload script for one time payment if needed
		if (registration_period < 0 && currentPaypalPaymentMethod !== Constants.PaypalOneTimePayment) {
			console.log('loading one time payment');

			reloadPayPalScript(currentPaypalPaymentMethod, () => {
				this.setState({ l: 1 });
			});
			currentPaypalPaymentMethod = Constants.PaypalOneTimePayment;
		}
		// reload script for subscription if needed
		if (registration_period >= 0 && currentPaypalPaymentMethod !== Constants.PaypalSubscriptionPayment) {
			console.log('loading subscription');
			reloadPayPalScript(currentPaypalPaymentMethod, () => {
				this.setState({ l: 1 });
			});
			currentPaypalPaymentMethod = Constants.PaypalSubscriptionPayment;
		}
	};

	getPaymentIframeSrc = () => {
		const totalPrice = this.state.totalPrice;
		const currency = this.state.currency;
		const numberOfInstallments = this.state.maxNumberOfInstallments;
		const payerName = this.state.fullName;
		const payerEmail = this.state.validEmailAddress;
		const title = `UniMastery Virtual Workshop - ${this.state.customerFieldValue}`;
		const description = 'workshop';
		const customer = this.getCustomerParam();
		const phone = this.state.phoneNumber;

		var request = require('request');

		var options = {
			method: 'POST',
			// prod:
			url: 'https://us-central1-classme-150316.cloudfunctions.net/getCGPaymentLinkAcademies',
			// qa:
			//url: 'https://us-central1-classme-integ.cloudfunctions.net/getCGPaymentLinkAcademies',
			//url: 'https://us-central1-classme-150316.cloudfunctions.net/getCGPaymentLinkQA',
			//local:
			//url: 'http://localhost:5000/classme-integ/us-central1/getCGPaymentLinkAcademies',
			headers: {
				'cache-control': 'no-cache',
				'content-type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*'
			},
			form: {
				email: payerEmail,
				name: payerName,
				price: totalPrice,
				currency: currency,
				title: title,
				description: description,
				learning_center: customer,
				phone: phone,
				maxInstallments: numberOfInstallments
			}
		};

		request(options, (error, response, body) => {
			console.log(body);
			if (error) throw new Error(error);
			this.setState({ paymentIframeSrc: body, showLoader: false });
		});
	};

	render() {
		try {
			const serializedCoupon = JSON.stringify(this.state.couponCode);
			localStorage.setItem('coupon', serializedCoupon);
		} catch (err) {
			return undefined;
		}

		const { step } = this.state;
		const {
			fullName,
			phoneNumber,
			validPhoneNumber,
			countryCode,
			validEmailAddress,
			citySelection,
			schoolInput,
			classSelection,
			classSubSelection,
			subjectPath,
			is1on1Class,
			courseSelectedOffset,
			language,
			classRadio,
			cancelDays,
			showTrialPeriod,
			clssesSubclassesValues,
			courses,
			paymentProcessor
		} = this.state;
		const values = {
			fullName,
			phoneNumber,
			validPhoneNumber,
			countryCode,
			validEmailAddress,
			citySelection,
			classSubSelection,
			schoolInput,
			classSelection,
			subjectPath,
			is1on1Class,
			courseSelectedOffset,
			language,
			classRadio,
			cancelDays,
			showTrialPeriod,
			clssesSubclassesValues,
			courses,
			paymentProcessor
		};

		let mainContent = null;

		switch (step) {
			case 0:
				mainContent = (
					<FirstPage
						nextStep={this.nextStep}
						handleChangeName={this.handleChangeName}
						handleChangePhoneNumber={this.handleChangePhoneNumber}
						handleCityValues={this.handleCityValues}
						handleSchool={this.handleSchool}
						handleChangeLanguage={this.handleChangeLanguage}
						values={values}
						cancel={this.cancel}
						currStep={this.state.step}
						totalSteps={this.state.totalSteps}
						validPhoneNumber={this.state.validPhoneNumber}
						countryCode={this.state.countryCode}
						invalidPhone={this.state.invalidPhone}
						invalidSchoolname={this.state.invalidSchoolname}
						handleCheckboxTrial={this.handleCheckboxTrial}
						handleChangeClassRadio={this.handleChangeClassRadio}
						saveToLocalStorage={this.saveToLocalStorage}
						finish={this.finish}
						isUserPaying={this.state.isUserPaying}
						paymentProcessor={this.state.paymentProcessor}
						trialData={this.state.trialData}
						canChooseGender={this.state.canChooseGender}
						canChooseLanguage={this.state.canChooseLanguage}
						language={this.state.language}
						currency={this.state.currency}
						constantSchool={this.state.constantSchool}
						customerFieldValue={this.state.customerFieldValue}
						customerFieldLabel={this.state.customerFieldLabel}
						handleChangeEmailAddress={this.handleChangeEmailAddress}
					/>
				);
				break;
			case 1:
				mainContent = (
					<SecondPage
						courseTitles={this.state.courseTitles}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						values={values}
						cancel={this.cancel}
						currStep={this.state.step}
						totalSteps={this.state.totalSteps}
						handleChangeCourse={this.handleChangeCourse}
						handleChangeSubjectPath={this.handleChangeSubjectPath}
						handleChangecourseTitles={this.handleChangecourseTitles}
						courses={this.state.courses}
						handleChangeSubcourseTitles={this.handleChangeSubcourseTitles}
						tempClassesArray={this.state.tempClassesArray}
						addNewClassObject={this.addNewClassObject}
						setSelectedCourseData={this.setSelectedCourseData}
						enableAddNewClass={this.state.enableAddNewClass}
						removeClassObject={this.removeClassObject}
						saveToLocalStorage={this.saveToLocalStorage}
						enableRemoveClass={this.state.enableRemoveClass}
						optionalGrades={this.state.optionalGrades}
						language={this.state.language}
						currency={this.state.currency}
						showGradeList={this.state.showGradeList}
						course_selection_field_label={this.state.course_selection_field_label}
						paymentProcessor={this.state.paymentProcessor}
						totalPrice={this.state.totalPrice}
						numberOfPayments={this.state.numberOfPayments}
						subsctiptionPlanId={this.state.subsctiptionPlanId}
						onPayPalPaymentSuccess={this.onPayPalPaymentSuccess}
						finish={this.finish}
					/>
				);
				break;

			case 2:
				mainContent = (
					<ThirdPage
						finish={this.finish}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						values={values}
						coupon={this.state.couponCode}
						cancel={this.cancel}
						currStep={this.state.step}
						totalSteps={this.state.totalSteps}
						priceCombination={this.state.priceCombination}
						couponError={this.state.couponError}
						openMessageModal={this.state.openMessageModal}
						handleModalClose={this.handleModalClose}
						saveToLocalStorage={this.saveToLocalStorage}
						tempClassesArray={this.state.tempClassesArray}
						hideLoader={this.hideLoader}
						showLoader={this.state.showLoader}
						iframeSrc={this.state.paymentIframeSrc}
						getPaymentIframeSrc={this.getPaymentIframeSrc}
						paymentProcessor={this.state.paymentProcessor}
					/>
				);
				break;

			case 3:
				mainContent = (
					<Success
						finish={this.finish}
						successData={this.state.successData}
						saveToLocalStorage={this.saveToLocalStorage}
						language={this.state.language}
					/>
				);
				break;

			default:
				console.log('Invalid step', step);
				break;
		}
		return (
			<div className="main">
				{step <= 2 ? (
					<div className="image-mobile">
						<center>
							<img src={require('../assets/unilogo-60x60.png')} alt="logo" width="60" height="60" />
							{/* <p className="logo-text">Meet Your Super Learners</p> */}
						</center>
					</div>
				) : null}
				{mainContent}
			</div>
		);
	}
}

export default Finals;
