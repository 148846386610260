import React, { Component } from "react";
import { Finals } from "./components/Finals";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Finals />
      </div>
    );
  }
}

export default App;
