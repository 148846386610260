import React from "react";
// import ReactDOM from 'react-dom';

import { hydrate, render } from "react-dom";
//import { render } from 'react-snapshot'
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");

//render(<ltr><App /></ltr>, rootElement);

if (rootElement.hasChildNodes()) {
  hydrate(
      <App />,
    rootElement
  );
} else {
  render(
    <App />,
    rootElement
  );
}

serviceWorker.unregister();
