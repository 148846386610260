import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ProgressBar from './ProgressBar';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PayPalButton } from 'react-paypal-button-v2';
import * as Constants from '../constants';
import UniMasteryMobileLogo from '../assets/UniMasterymobilelogo.png';

import '../styles/main.css';
import '../styles/overrideMaterialUI.css';


const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const mobileViewport = vw < 768 ? true : false;

const themeEn = createMuiTheme({
	direction: 'ltr'
});
const themeHeb = createMuiTheme({
	direction: 'ltr'
});

const StyledButtonNextEn = withStyles({
	root: {
		height: '48px',
		background: '#32C6A9 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		opacity: 1,
		shadow: 'none',
		fontSize: mobileViewport ? '14px' : '18px',
		fontWeight: 'bold',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#32C6A9',
			boxShadow: '0px 3px 6px #32C6A9B3'
		},
		'&:active': {
			backgroundColor: '#4A4547'
		}
	}
})(Button);

const StyledButtonNextHeb = withStyles({
	root: {
		height: '48px',
		background: '#32C6A9 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		opacity: 1,
		shadow: 'none',
		fontSize: mobileViewport ? '14px' : '18px',
		fontWeight: 'bold',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#32C6A9',
			boxShadow: '0px 3px 6px #32C6A9B3'
		},
		'&:active': {
			backgroundColor: '#4A4547'
		}
	}
})(Button);

const StyledFormControlEn = withStyles({
	root: {
		display: 'flex',
		marginBottom: mobileViewport ? '20px' : '32px',

		'& label': {
			color: '#4A4547',
			fontSize: mobileViewport ? '14px' : '16px'
		},

		'& label.Mui-focused': {
			color: '#32C6A9',
			fontSize: mobileViewport ? '14px' : '16px'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #707070',
				padding: '0px'
			},
			'&.Mui-focused fieldset': {
				border: '2px solid #32C6A9',
				paddingTop: '20px',
				color: 'black'
			},
			padding: '0px',
			height: '48px'
		}
	}
})(FormControl);

const StyledFormControlHeb = withStyles({
	root: {
		display: 'flex',
		marginBottom: mobileViewport ? '20px' : '32px',

		'& label': {
			color: '#4A4547',
			fontSize: mobileViewport ? '14px' : '16px'
		},

		'& label.Mui-focused': {
			color: '#32C6A9',
			fontSize: mobileViewport ? '14px' : '16px'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #707070',
				padding: '0px'
			},
			'&.Mui-focused fieldset': {
				border: '2px solid #32C6A9',
				paddingTop: '20px',
				color: 'black'
			},
			padding: '0px',
			height: '48px'
		}
	}
})(FormControl);

export class SecondPage extends Component {
	// PAYPAL
	paypalSubscribe = (data, actions) => {
		return actions.subscription.create({
			plan_id: this.props.subscriptionPlanId
		});
	};

	paypalOnError = (err) => {
		console.log('paypalOnError error :' + err);
	};

	// Component Life Cycle
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	saveToLocalStorage = () => {
		this.props.saveToLocalStorage();
	};

	continue = async (e) => {
		e.preventDefault();
		this.saveToLocalStorage();
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const {
			values,
			language,
			handleChangeCourse,
			onPayPalPaymentSuccess,
			onPaypalSubscriptionSuccess
		} = this.props;

		const theme = language === 'HEB' ? themeHeb : themeEn;
		const StyledButtonNext = language === 'HEB' ? StyledButtonNextHeb : StyledButtonNextEn;
		const StyledFormControl = language === 'HEB' ? StyledFormControlHeb : StyledFormControlEn;
		const direction = language === 'HEB' ? 'rtl' : 'ltr';
		const isPayPalProcessor = this.props.paymentProcessor === Constants.PAYPAL_PROCESSOR;
		const isOneTimePayment =
			this.props.totalPrice &&
			this.props.totalPrice > 0 &&
			this.props.numberOfPayments &&
			this.props.numberOfPayments < 0;
		const isSubscription =
			this.props.totalPrice &&
			this.props.totalPrice > 0 &&
			this.props.numberOfPayments &&
			this.props.numberOfPayments >= 0;

		return (
			<React.Fragment>
				<Grid container spacing={0}>
					<Grid item xs={2}>
						<div className="white-space-side">
							<img
								className="pageImg"
								src={require('../assets/UniMastery120x120.png')}
								title="first page"
								alt="pic"
							/>
						</div>
					</Grid>
					<Grid item xs={8}>
						<div dir={direction}>
							<ProgressBar
								currStep={this.props.currStep}
								totalSteps={this.props.totalSteps}
								language={this.props.language}
							/>
						</div>
						<form onSubmit={this.continue} className="page-form">
							<div className="main-content">
								<div className="wrapper" dir="ltr" style={{ overflow: 'hidden' }}>
									{/* choose path */}
									<ThemeProvider theme={theme}>
										<StyledFormControl variant="outlined" required>
											<Select
												native
												value={
													values.courseSelectedOffset === '' ? (
														''
													) : (
														values.courseSelectedOffset
													)
												}
												onChange={(e) => handleChangeCourse(e)}
												style={{
													fontSize: mobileViewport ? '14px' : '16px',
													color: '#4A4547'
												}}
											>
												<option
													disabled
													value={''}
													style={{
														borderBottom: '1px solid #E5E5E5',
														display: 'none'
													}}
												>
													{this.props.course_selection_field_label}*
												</option>
												{this.props.courseTitles.map((path, index) => {
													return (
														<option
															value={index}
															key={index}
															style={{ borderBottom: '1px solid #E5E5E5' }}
														>
															{path}
														</option>
													);
												})}
											</Select>
										</StyledFormControl>

										{isPayPalProcessor && isSubscription && this.props.totalPrice > 0 ? (
											<div id="paypal-button-container">
												<br />
												<br />
												<br />
												<PayPalButton
													options={{
														vault: true,
														clientId: Constants.PAYPAL_CLIENT_ID,
														intent: 'subscription'
													}}
													amount={this.props.totalPrice}
													currency="USD"
													createSubscription={this.paypalSubscribe}
													onApprove={(details, data) => {
														onPaypalSubscriptionSuccess(details, data);
														this.props.nextStep();
													}}
													catchError={this.paypalOnError}
													onError={this.paypalOnError}
													onCancel={this.paypalOnError}
												/>
											</div>
										) : (
											''
										)}

										{isPayPalProcessor && isOneTimePayment && this.props.totalPrice > 0 ? (
											<div id="paypal-button-container">
												<br />
												<br />
												<br />
												<PayPalButton
													options={{
														clientId:Constants.PAYPAL_CLIENT_ID
													}}
													amount={this.props.totalPrice}
													shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
													onSuccess={(details, data) => {
														console.log('onSuccess-> data is: ' + JSON.stringify(data));
														console.log(
															'onSuccess-> DETAILS is: ' + JSON.stringify(details)
														);
														console.log('payment sucess!');
														onPayPalPaymentSuccess(
															details.purchase_units[0].payments.captures[0].id,
															details.purchase_units[0].amount.value
														);
														this.props.nextStep();
														// OPTIONAL: Call your server to save the transaction
														return fetch('/paypal-transaction-complete', {
															method: 'post',
															body: JSON.stringify({
																orderID: data.orderID
															})
														});
													}}
												/>
											</div>
										) : (
											''
										)}
									</ThemeProvider>
									{/* end choose path */}
								</div>

								{/* white space div */}
								<div className="white-space-wrapper-bottom" />
							</div>

							{/* START Back - Continue Buttons */}
							{/* ENGLISH */}
							{direction === 'ltr' ? (
								<div className="button-container" dir="ltr">
									<div className="button-back" variant="contained" onClick={this.back}>
										<i
											className="material-icons"
											style={{
												fontSize: '32px',
												marginRight: '5px',
												marginLeft: '-12px'
											}}
										>
											keyboard_arrow_left
										</i>
										Back
									</div>

									<StyledButtonNextEn
										color="secondary"
										variant="contained"
										type="submit"
										disabled={isPayPalProcessor && this.props.totalPrice > 0 ? true : false}
									>
										Continue
										<i
											className="material-icons"
											style={{
												fontSize: '32px',
												marginLeft: '5px',
												marginRight: '-12px'
											}}
										>
											keyboard_arrow_right
										</i>
									</StyledButtonNextEn>
								</div>
							) : (
								<div className="button-container" dir="rtl">
									{/** HEBREW */}
									<div className="button-back" variant="contained" onClick={this.back}>
										<i
											className="material-icons"
											style={{
												fontSize: '32px',
												marginLeft: '5px',
												marginRight: '-12px'
											}}
										>
											keyboard_arrow_right
										</i>
										חזור
									</div>
									<StyledButtonNextHeb
										color="secondary"
										variant="contained"
										type="submit"
										disabled={isPayPalProcessor && this.props.totalPrice > 0 ? true : false}
									>
										המשך
										<i
											className="material-icons"
											style={{
												fontSize: '32px',
												marginRight: '5px',
												marginLeft: '-12px'
											}}
										>
											keyboard_arrow_left
										</i>
									</StyledButtonNextHeb>
								</div>
                
            
							)}
								{/* unimastery logo (powered by) */}
								<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '16px'
								}}
							>
								<span class="Powered-By">Powered By</span>
								<img src={UniMasteryMobileLogo} class="Unimastery-mobile-logo" />
							</div>
              
							{/* END  Back - Continue Buttons */}

                      {/* if english */}
              {direction === 'ltr' ?        
							<div className="contact-info-div">
								<p className="contact-text-bottom" variant="caption" display="block">
									for any questions please contact us at
									<br />
									טלפון: <a href={'tel:052-8808558'}>052-8808558</a> Email:{' '}
									<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
								</p>
								<p className="terms">
									<br />
									<a href={'https://class.me/termsHE.html'}>Terms of use and privacy policy</a>
								</p>
							</div> 
              
              : // or (if not english)

                      // if hebrew
              	<div className="contact-info-div">
								<p className="contact-text-bottom" variant="caption" display="block">
                לכל שאלה ובקשה ניתן לשלוח דוא"ל לכתובת
									<br />
									טלפון: <a href={'tel:052-8808558'}>052-8808558</a> מייל:{' '}
									<a href={'mailto:contact@unimastery.com'}>contact@unimastery.com</a>
								</p>
								<p className="terms">
									<br />
									<a href={'https://class.me/termsHE.html'}>תנאי שימוש ומדיניות פרטיות</a>
								</p>
							</div>
             }
						</form>
					</Grid>

					<Grid item xs={2}>
						<div className="white-space-side" />
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
	}

export default SecondPage;
