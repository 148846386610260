import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

const vw = Math.max(
  document.documentElement.clientWidth,
  window.innerWidth || 0
);
console.log("VIEWPORT", vw);
const mobileViewport = vw < 768 ? true : false;
console.log("VIEWPORT MOBILE", mobileViewport);

const ColorlibConnectorEn = withStyles({
  alternativeLabel: {
    top: 17,
    right: "calc(50% + 20px)",
    left: "calc(-50% + 20px)",
  },

  active: {
    "& $line": {
      backgroundColor: "#32C6A9",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#32C6A9",
    },
  },
  line: {
    height: 1,
    backgroundColor: "#4A4547",
  },
})(StepConnector);

const ColorlibConnectorHeb = withStyles({
  alternativeLabel: {
    top: 17,
    right: "calc(-50% + 20px)",
    left: "calc(50% + 20px)",
  },

  active: {
    "& $line": {
      backgroundColor: "#32C6A9",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#32C6A9",
    },
  },
  line: {
    height: 1,
    backgroundColor: "#4A4547",
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid #4A4547",
    width: mobileViewport ? "24px" : "36px",
    height: mobileViewport ? "24px" : "36px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A4547",
  },
  active: {
    width: mobileViewport ? "24px" : "36px",
    height: mobileViewport ? "24px" : "36px",
    border: "1px solid #32C6A9",
    opacity: 1,
    color: "#32C6A9",
  },
  completed: {
    width: mobileViewport ? "24px" : "36px",
    height: mobileViewport ? "24px" : "36px",
    border: "1px solid #32C6A9",
    background: "#32C6A9 0% 0% no-repeat padding-box",
    opacity: 1,
    color: "#FFFFFF",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps(totalSteps, props) {
  const steps = props.language === "ENG" ? ["Personal Info", "Selection", "Finish"] : ["הכנסת פרטים", "בחירה", "סיום"];
  return steps.slice(0, totalSteps);
}

export default function CustomizedSteppers(props) {
  let currStep = props.currStep+1;
  console.log("stepper step is:", currStep);

  let lang = props.language;
  console.log("stepper language is:", lang);

  const steps = getSteps(props.totalSteps, props);
  const ColorlibConnector = lang === "HEB" ? ColorlibConnectorHeb : ColorlibConnectorEn;

  return (  
    <div>
      <Stepper
        alternativeLabel
        activeStep={currStep}
        style={{ background: "rgba(255, 255 ,255 ,0)" }}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
